<template>
  <button
    :class="['pk-button', sizeClass, { 'pk-button--disabled': disabled }]"
    :type="type"
    :disabled="disabled"
    @click="handleClick"
  >
    <slot></slot>
    <slot name="icon" />
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    type: {
      type: String,
      default: "button", // può essere 'button', 'submit', 'reset'
    },
    size: {
      type: String,
      default: "medium", // supporta 'small', 'medium', 'large'
      validator: (value) => ["small", "medium", "large"].includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sizeClass() {
      return `pk-button--${this.size}`;
    },
  },
  methods: {
    handleClick(event) {
      if (!this.disabled) {
        this.$emit("click", event);
      }
    },
  },
};
</script>

<style scoped>
.pk-button {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0.5em 1em;
  font-size: 1rem;

  cursor: pointer;

  height: 48px;
  padding: 10px 16px;

  background-color: white;

  border-radius: 8px;
  border: 1px solid #e2e9f1;
}

.pk-button--small {
  padding: 0.25em 0.75em;
  font-size: 0.875rem;
}

.pk-button--medium {
  padding: 0.5em 1.25em;
  font-size: 1rem;
}

.pk-button--large {
  padding: 0.75em 1.5em;
  font-size: 1.125rem;
}

.pk-button--disabled {
  background-color: #ccc;
  cursor: not-allowed;
  pointer-events: none;
}
</style>
