<script setup>
import { ref } from "vue";
import { IonDatetime } from "@ionic/vue";
import master from "./layout/master.vue";
import Button from "./components/Button.vue";

import MasterLayout from "../../components/MasterLayout.vue";

// Lista di items con nome utente
const items = ref([
  { id: 1, name: "Alice" },
  { id: 2, name: "Bob" },
  { id: 3, name: "Charlie" },
  { id: 4, name: "Diana" },
  { id: 5, name: "Eve" },
  { id: 6, name: "Frank" },
  { id: 7, name: "Grace" },
  { id: 8, name: "Hank" },
  { id: 9, name: "Ivy" },
  { id: 10, name: "Jack" },
]);

// Per tenere traccia dell'elemento selezionato
const selectedItem = ref(null);

// Funzione per gestire il click su un box
const handleClick = (index) => {
  selectedItem.value = index;
};
</script>

<template>
  <master-layout :showBack="false" smallTitle="Induction" bigTitle="Plan">
    <div class="m-4">
      <span class="text-16 font-bold">Choose your mentor</span>
    </div>

    <div class="scroll-container">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="scroll-item"
        :class="{ selected: selectedItem === index }"
        @click="handleClick(index)"
      >
        <img
          :src="'https://i.pravatar.cc/100?u=' + item.id"
          class="scroll-image"
        />
        <span class="item-name">{{ item.name }}</span>
        <!-- Nome utente sotto l'immagine -->
      </div>
    </div>
    <div class="w-full my-4">
      <ion-datetime></ion-datetime>
    </div>
    <div
      class="w-full flex justify-content-center align-items-center py-2 px-4"
    >
      <button class="w-full flex justify-content-center button-primary p-d">
        Save
      </button>
    </div>
  </master-layout>

  <!-- <master>
    <template #header>
      <div class="title-header">
        <span class="mb-2">Book your</span>
        <span class="font-bold">Mentor</span>
      </div>
    </template>

    <div class="separator"></div> -->

  <!-- </master> -->
</template>

<style scoped>
.separator {
  height: 1px;
  width: 100%;
  background-color: #e2e9f1;
}

.title-header {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.scroll-container {
  display: flex;
  overflow-x: auto; /* Scroll orizzontale */
  padding: 0 20px;
  gap: 10px;
  white-space: nowrap; /* Impedisce agli elementi di andare a capo */
}

.scroll-container::-webkit-scrollbar {
  display: none; /* Nasconde la scrollbar */
}

.scroll-item {
  width: 66px; /* Larghezza del box */
  height: 90px; /* Altezza del box aumentata per ospitare il nome */
  border-radius: 8px; /* Border radius del box */
  background-color: var(--ion-color-secondary);
  display: flex;
  flex-direction: column; /* Imposta il layout verticale */
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  flex-shrink: 0; /* Evita che gli elementi si comprimano */
}

.scroll-item.selected {
  background-color: #f4f8fd; /* Colore di sfondo quando selezionato */
}

.scroll-image {
  width: 50px;
  height: 50px;
  border-radius: 50%; /* Immagine rotonda */
  object-fit: cover;
}

.item-name {
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
  color: #333; /* Colore del testo */
}
</style>
